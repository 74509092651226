@import '@radix-ui/colors/black-alpha.css';

.confirm-popup-overlay {
  background-color: var(--black-a9);
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.confirm-popup-wrapper {
  box-shadow: hsla(210, 3%, 34%, 0.35) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 417px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.confirm-popup-wrapper:focus {
  outline: none;
}

.confirm-popup-font {
  font-size: 21px;
  line-height: 25.41px;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
