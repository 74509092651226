.sidebar {
    width: 210px;
    .logo {
        width: 65px;
    }

    .divider {
        width: 38px;
        height: 7px;
    }

    .sidebar-tab {
        width: 152px;
    }
}

.active {
    border-radius: 20px;
}