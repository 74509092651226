@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Noto Sans Japanese';
  src: url('./fonts/NotoSansJP-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Noto Sans Japanese';
  src: url('./fonts/NotoSansJP-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Noto Sans Japanese';
  src: url('./fonts/NotoSansJP-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Noto Sans Japanese';
  src: url('./fonts/NotoSansJP-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Noto Sans Japanese';
  src: url('./fonts/NotoSansJP-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Noto Sans Japanese';
  src: url('./fonts/NotoSansJP-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Noto Sans Japanese';
  src: url('./fonts/NotoSansJP-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Noto Sans Japanese';
  src: url('./fonts/NotoSansJP-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Noto Sans Japanese';
  src: url('./fonts/NotoSansJP-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

* {
  font-family: 'Noto Sans Japanese', sans-serif;
}

body {
  margin: 0;
  cursor: default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input[type='password'] {
  font-family: Verdana;
  letter-spacing: 0.125em;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b5b5b5;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
