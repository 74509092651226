#toolbar {
  @apply w-full flex items-center flex-wrap gap-1;
}
#toolbar,
.border-rounded .ql-container {
  @apply border-none;
}
#toolbar .ql-header[value="1"]:after {
  @apply content-["H1"];
}

#toolbar .ql-header[value="2"]:after {
  @apply content-["H2"];
}

#toolbar .heading-button svg {
  @apply hidden;
}
#toolbar .heading-button,
#toolbar .ql-bold,
#toolbar .ql-italic,
#toolbar .ql-underline,
#toolbar .ql-italic,
#toolbar .ql-list,
#toolbar .ql-code-block,
#toolbar .ql-image {
  @apply text-secondary-500 w-[36.07px] h-[34px] rounded-md font-semibold;
}
#toolbar .heading-button {
  @apply text-xl;
}

#toolbar .ql-stroke {
  @apply stroke-secondary-500;
}

#toolbar .ql-active,
#toolbar .ql-active .ql-stroke {
  @apply bg-secondary-500 text-[#d9d9d9] stroke-[#d9d9d9];
}
.toolbar-divider {
  @apply bg-outline-200 mx-1 w-[2px] h-[13px];
}
.ql-editor {
  @apply overflow-auto;
  cursor: pointer;
}
.ql-editor * {
  cursor: text;
}
