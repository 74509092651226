.defaultSwitchRoot {
  box-shadow: 29px 61px 0px -59px rgba(128, 128, 128, 1) inset;
  @apply relative w-[46px] h-[19px] rounded-full bg-blackA6;
}

.defaultSwitchRoot[data-state="checked"] {
  box-shadow: 29px 61px 0px -59px rgba(169, 39, 69, 1) inset;
  @apply bg-primary-500;
}

.defaultSwitchThumb {
  @apply block w-[17px] h-[17px] rounded-full bg-[#F0F0F0] transition-transform transform translate-x-[1px] will-change-transform;
}
.defaultSwitchThumb[data-state="checked"] {
  @apply translate-x-[28px];
}
